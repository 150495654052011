body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #dedede;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #0956A1;
  color: white;
  padding: 20px 0;
}

h1 {
  margin: 0;
  font-size: 28px;
  text-transform: uppercase;
}

.current-date-time {
  font-size: 18px;
  margin-top: 10px;
}

.ranges-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-grow: 1;
  align-items: center;
}

.range-bookings {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 15px;
  width: calc(25% - 15px);
  height: 80vh;
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.range-bookings h3 {
  color: #0956A1;
  border-bottom: 2px solid #0956A1;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.booking-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  position: relative;
}

.booking-item h4 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #333;
}

.booking-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.booking-entry {
  margin-bottom: 15px;
}

.booking-time {
  font-weight: bold;
  margin-bottom: 5px;
}

.booking-group {
  margin-left: 10px;
}

.booking-name {
  margin-bottom: 3px;
}

.booking-item.past {
  background-color: #f0f0f0;
  text-transform: capitalize;
}

.booking-item.current {
  background-color: #e6ffe6;
  height: 200px;
  overflow: auto;
  text-transform: capitalize;
}

.booking-item.current::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: #2e8b57;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.booking-item.next {
  background-color: #fff0e0;
  height: 100px;
  overflow: auto;
  text-transform: capitalize;
}

.booking-item.after-next {
  background-color: #fff0f0;
  height: 100px;
  overflow: auto;
  text-transform: capitalize;
}

.no-booking {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #999;
  font-style: italic;
}

.available-stands {
  margin-top: auto;
  padding: 10px;
  background-color: #e6f7ff;
  border-radius: 0 0 8px 8px;
  font-weight: bold;
  color: #0956A1;
}

.available-stands p {
  margin: 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-circle {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #0956A1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  color: #d32f2f;
  padding: 20px;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .ranges-container {
    flex-wrap: wrap;
  }
  .range-bookings {
    width: calc(50% - 30px);
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .range-bookings {
    width: calc(100% - 30px);
  }
}

.App.blurred > *:not(.login-modal-overlay) {
  filter: blur(5px);
}