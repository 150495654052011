.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-modal {
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  width: 280px;
  text-align: center;
}

.user-icon {
  width: 60px;
  height: 60px;
  background-color: #1f2687;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -60px auto 20px;
}

.user-icon svg {
  width: 30px;
  height: 30px;
  color: white;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #1f2687;
}

.form-group input {
  width: calc(100% - 50px);
  padding: 10px 10px 10px 40px;
  border: none;
  background-color: #e8f0fe;
  border-radius: 5px;
  font-size: 16px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #1f2687;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

button:hover {
  background-color: #161c66;
}